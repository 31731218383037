import BestgamerLogo from "../assets/bestgamer/bg_logo_neg.svg";
import BestgamerLargeLogo from "../assets/bestgamer/bestgamerLarge.svg";
// import favicon from "../assets/elgiganten/favicon/android-chrome-512x512.png";

export const bestgamer_brandVariables = {
  siteTitle: "Best Gamer",
  siteIcon: undefined,
  name: "Best Gamer",
  name_small: "BG",
  logos: {
    large: BestgamerLargeLogo,
    white: BestgamerLogo,
    small: BestgamerLogo,
    medium: BestgamerLogo,
    alt_text: "Best Gamer logo",
    profile: BestgamerLogo,
  },
  brandTheme: "bestgamer", // Theme of the brand
  site: "", // Live url of site
  authMethod: "emailNoVerify", // Only needed for events, gives user set options to manualy sign up, options are:  email | phone
  defaultCountryCode: "DK", // Only needed for retail platforms | For initial phone number | Changes all auth methods`
  defaultLanguage: "eng",
  brandStoreExtension: "", // Only needed for retail platforms | Extension used for email
  brandValue: "BESTGAMER", // Only needed for retail platforms | Brand value needed for BE related functions
  brandIntials: "bg", // gets added after the username, to drasticaly descrease chance of duplicate usernames across different apps | Only needed for event platforms
  defaultNavigateUrl: "/",
  eventHasAutoQrSignup: false,
  hasSubClients: true,
  subClientsForOverviews: ["PARTNER", "YOUSEE"], // string[] of clients used to fetch multiple types of clients for overview pages
  autoJoinChallenge: true, // FIX BEFORE DREAMHACK
  hasFeature: {
    notifications: false,
    themes: false,
    connections: false,
    achievements: false,
    quickSignUp: false,
    messages: false,
    teams: false,
    news: false,
    challenges: true,
    singleChallenge: true,
    gathers: false,
    preferences: false,
    friends: false,
    zones: false,
    feed: false,
    winners: false,
    qrSignUp: true,
    manualSubmissions: false,
    landing: true,
    login: true,
    haslanguagePicker: true,
    getstarted: false,
    hero: false,
    search: false,
    routes: true,
    dailytasks: false,
    games: false,
    treasure_hunt: true,
    activities: true,
  },
  hasAuth: {
    steam: false,
    bnet: false,
    quickSignUp: true,
    google: true,
  },
  layout: {
    challenge: "bestgamer",
  },
  event: {
    forcedCredentialPopup: false,
    eventCode: "bestgamer_-_-_2025", // Only needed for event platforms
    collectionForSubmissions: "",
    documentForSubmissions: "",
    isEvent: true,
    spacecoins_redirect: true,
  },
};
